<template>
    <div>
        <div class="card">
            <div class="bg-blue-light px-1 rounded pb-2">
                <TitleButton
                        class="mt-1"
                        btnTitle="Go Back"
                        title="Create Journal"
                        @onClickCloseButton="goToList"
                />
                <div class="row mt-1">
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label class="col-form-label">Voucher Date</label>
                        <input
                            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                            tabindex="1"
                            type="date"
                            placeholder=""
                            v-model="journal.date"
                        >
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                      <label for="colFormLabel" class="col-form-label">PR / PO Reference</label>
                      <div class="input-group mb-3">
                        <input v-model="journal.txn_no" type="text" class="form-control" placeholder="PR / PO Referance">
                        <button @click="openPrPoModal()" class="btn btn-outline-secondary" type="button" id="button-addon2">Select</button>
                      </div>
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                      <label for="colFormLabel" class="col-form-label">Tag Register</label>
                      <v-select
                        placeholder="Select Tag Register"
                        class="w-100"
                        :options="tagRegisters"
                        label="name"
                        :reduce="name => name.id"
                        v-model="journal.tag_register_id"
                      />
                    </div>
                </div>
            </div>

            <div class="px-2 mt-2">
                <AddFormElement
                        class="mb-2"
                        :businesses="businesses"
                        :accountHeads="accountHeads"
                        :ngoabHeads="ngoabHeads"
                        :budgets="budgets"
                        :programs="programs"
                        :projects="projects"
                        :costCentres="costCentres"
                        :contactProfiles="contactProfiles"
                        :paymentDate="journal.date"
                        v-for="(item, index) in journal.general_ledgers"
                        :key="index"
                        :index="index"
                        :item="item"
                        @onClose="onClose"
                        @isLoading="isDataLoading = true"
                        @loaded="isDataLoading = false"
                />
                <div class="row me-1 mt-3 px-2">
                    <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
                        <AddButton title="Add line" @onClickAdd="onClickAdd"/>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-2"><input readonly type="number" class="form-control text-end" :value="totalDebit"></div>
                    <div class="col-6 col-sm-4 col-lg-2"><input readonly type="number" class="form-control text-end" :value="totalCredit"></div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="attachment" class="form-label fw-bold">Attachment</label>
                            <BlobFileViewer :attachments="journal.attachments" />
                            <FileUpload
                                    :is-drag-active="true"
                                    button-text="Upload Documents"
                                    v-model="journal.attachments"
                            >
                            </FileUpload>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="note" class="form-label fw-bold">Memo</label>
                            <textarea v-model="journal.note" placeholder="Memo" class="form-control" rows="2" id="note"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
                <hr class="mb-1">
                <button
                    @click="saveJournal(true)"
                    :disabled="!isDebitCreditEqual || loading"
                    class="btn btn-primary"
                >
                    <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Save
                </button>
                <button
                    @click="saveJournal(false)"
                    :disabled="!isDebitCreditEqual || saveNewLoader"
                    class="btn btn-primary"
                >
                    <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Save & New
                </button>
                <button
                    @click="saveJournal(true, true)"
                    :disabled="!isDebitCreditEqual || loading"
                    class="btn btn-primary"
                >
                    <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Save & Print
                </button>
                <button
                        @click="goToList"
                        class="btn btn-outline-secondary cancel-btn"
                >
                    Cancel
                </button>
            </div>
            <!-- PR PO Modal -->
            <PrPoModal
              :lists="prPoLists"
              ref="prPoModal"
              @selectedPrPo="selectedPrPo"
            />
            <Loader v-if="isDataLoading"/>
        </div>
    </div>
</template>

<script setup>
import handleJournal from '@/services/modules/journal'
import handleContact from '@/services/modules/contact'
import handleCBusinesses from '@/services/modules/businesses'
import {computed, inject, onMounted, watch, ref} from 'vue'
import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/ngo/journal/AddFormElement'
import AddButton from '@/components/atom/AddButton'
import {useRoute, useRouter} from "vue-router";
import handleProjects from "@/services/modules/procurement/project";
import handleNGOPrograms from "@/services/modules/ngo/program";
import handleCostCentres from "@/services/modules/ngo/costCentre";
import handleActivities from "@/services/modules/ngo/activity";
import handleFD6Projects from "@/services/modules/procurement/fd6";
import BlobFileViewer from "@/components/atom/BlobFileViewer";
import FileUpload from "@/components/atom/FileUpload";
import Loader from '@/components/atom/LoaderComponent'
import figureFormatter from "@/services/utils/figureFormatter";
import PrPoModal from '@/components/molecule/ngo/payment/PrPoModal'
import handleTagRegister from '@/services/modules/scm/tag-register'
import pdfPrinterHelper from '@/services/utils/pdfPrinterHelper';

const {fetchAccountHeadsList, createJournal, loading, fetchJournal} = handleJournal()
const {fetchContactProfiles} = handleContact()
const {fetchBusinessList} = handleCBusinesses()
const {fetchProjects} = handleProjects()
const {fetchProgramList} = handleNGOPrograms()
const {fetchCostCentreList} = handleCostCentres()
const {fetchActivityList} = handleActivities()
const {fetchFD6AccountsAll} = handleFD6Projects()
const {decimalFormat} = figureFormatter();
const {fetchTagRegisterList, fetchPrPoList} = handleTagRegister()
const {generatePdf} = pdfPrinterHelper();

const showError =  inject('showError');
const showSuccess =  inject('showSuccess');
const accountHeads = ref([])
const saveNewLoader = ref(false)
const contactProfiles  = ref([])
const businesses  = ref([])
const projects = ref([])
const ngoabHeads = ref([])
const programs = ref([])
const costCentres = ref([])
const activities = ref([])
const budgets = ref([])
const prPoLists = ref([])
const tagRegisters = ref([])
const isDataLoading = ref(false)
const prPoModal = ref(null);

const journal = ref({
  company_id:'',
  contact_profile_id: '',
  business_id: '',
  date: '',
  note: '',
  attachments: [],
  mop_references: 'mop',
  voucher_type: 'journal_voucher',
  txn_no: null,
  tag_register_id: null,
  general_ledgers: [
    {
      business_id: null,
      account_head_id: null,
      debit: null,
      credit: null,
      note: '',
      tax_rate: null,
      taxable_amount: null,
      project_id: null,
      donor_id: null,
      budget_master_id: null,
      project_account_id: null,
      budget_general_id: null,
      ngoab_head_id: null,
      program_id: null,
      cost_centre_id: null,
      contact_profile_id: null
    },
    {
      business_id: null,
      account_head_id: null,
      debit: null,
      credit: null,
      note: '',
      tax_rate: null,
      taxable_amount: null,
      project_id: null,
      donor_id: null,
      budget_master_id: null,
      project_account_id: null,
      budget_general_id: null,
      ngoab_head_id: null,
      program_id: null,
      cost_centre_id: null,
      contact_profile_id: null
    }
  ]
})
const journalRes = ref({});
const $route = useRoute();
const $router = useRouter();

//computed
const isLoading = computed(() => loading || saveNewLoader)
const start = computed(() => $route.query.start)
const end = computed(() => $route.query.end)
const general = computed(() => journal.value.general_ledgers)
const totalDebit = computed( () => {
  let total = 0;
  general.value.map(i => {
    if(i.debit) total += i.debit
  })
  return total
})
const totalCredit = computed( () => {
  let total = 0;
  general.value.map(i => {
    if(i.credit) total += i.credit
  })
  return total
})
const isDebitCreditEqual = computed( () => totalCredit.value === totalDebit.value)

//methods
const openPrPoModal = () => {
  prPoModal.value.toggleModal()
}
const selectedPrPo = (pr_po) => {
  journal.value.txn_no = pr_po;
}

const goToPrint = (id) => {
  const companyQuery = `?company_id=${$route.params.companyId}`;
  fetchJournal(id, companyQuery).then((res) => {
    journalRes.value = res.data
  }).then(() => {
    generatePdf($route.params.companyId, journalRes.value, 'journal')
  }).catch((e) => {
    console.log('Something went wrong')
  })
}

const goToList = () => {
  let params = {
    companyId: $route.params.companyId,
    moduleId: $route.params.moduleId,
    menuId: $route.params.menuId,
    pageId: $route.params.pageId
  };
  let query = {
    start: start.value,
    end: end.value,
  }
  $router.push({
    query: query,
    name: 'journal-voucher-np',
    params: params
  })
}

const onClose = (index) => {
  journal.value.general_ledgers.splice(index, 1)
}

const onClickAdd = () => {
  journal.value.general_ledgers.push({
    account_head_id: null,
    debit: '',
    credit: '',
    note: '',
    tax_rate: null,
    taxable_amount: null
  })
}

const setVoucherNumberAndDate = (withoutDate = false) => {
    new Promise(async (resolve, reject) => {
        try {
            if (!withoutDate) {
                journal.value.date = new Date().toISOString().split('T')[0]
            }
            resolve();
        } catch (err) {
            reject(err);
        }
    })
}

const resetForm = async () => {
    journal.value = {
        company_id: '',
        date: '',
        note: '',
        mop_references: 'mop',
        voucher_type: 'journal_voucher',
        general_ledgers: [
            {
                business_id: null,
                account_head_id: null,
                debit: null,
                credit: null,
                note: '',
                tax_rate: null,
                taxable_amount: null,
                project_id: null,
                donor_id: null,
                budget_master_id: null,
                project_account_id: null,
                budget_general_id: null,
                ngoab_head_id: null,
                program_id: null,
                cost_centre_id: null,
                contact_profile_id: null
            }
        ]
    }
    await setVoucherNumberAndDate()
}

const getFormData = () => {
  let formData = new FormData();
  Object.keys(journal.value).forEach(i => {
    if(i !== 'general_ledgers' || i !== 'attachments' ) {
      formData.append(i, journal.value[i])
    }
  })

  let generalLedgerData = journal.value.general_ledgers.slice();
  generalLedgerData.map(ledger => {
    ledger.txn_no = journal.value.txn_no;
    ledger.tag_register_id = journal.value.tag_register_id;
  })

  formData.append('general_ledgers', JSON.stringify(generalLedgerData))

  if(journal.value.attachments) {
    for(let i=0; i<journal.value.attachments.length; i++) {
      let key = `attachments[${i}]`;
      let value = journal.value.attachments[i];
      formData.append(key, value);
    }
  }

  return formData;
}

const saveJournal = async (redirect = false, print = false) => {//createJournal
  journal.value.company_id = $route.params.companyId;
  let data = getFormData();
  if(redirect) {
    loading.value = true
  } else {
    saveNewLoader.value = true
  }
  try {
    let res = await createJournal(data)
    if(!res.status) {
      showError(res.message)
    }
    if(res.status) {
      showSuccess(res.message)
      resetForm()
      if(redirect && print) goToPrint(res.data.id)
      if(redirect){
        $router.push({
          name: 'journal-voucher-np',
          params: {
            companyId: $route.params.companyId,
            moduleId: $route.params.moduleId,
            menuId: $route.params.menuId,
            pageId: $route.params.pageId
          },
          query: {
            start: start.value,
            end: end.value
          }
        })                         
      }
    }
  } catch (err) {
    if(!err.response) {
      showError('Something is wrong. Check your connectivity!!')
    }
    if(err.response && err.response.status !== 406) {
      showError(err.response.data?.message)
    }
  } finally {
    loading.value = false
    saveNewLoader.value = false
  }
}

onMounted(async () => {
  isDataLoading.value = true;
  let companyId = $route.params.companyId
  let contactId = '';
  let q = '';
  let companyQuery = '?company_id=' + companyId;
  let query = companyQuery + '&q=' + q + '&contact_id' + contactId;
  let accountHeadQuery = companyQuery + '&cash_bank_hide=yes&status=active';
  Promise.all([

    fetchAccountHeadsList(accountHeadQuery).then(res => {
      if(res.data){
        accountHeads.value = res.data
      }
    }),
    fetchFD6AccountsAll(companyQuery).then(res => {
      if(res.data){
        ngoabHeads.value = res.data
      }
    }),
    fetchProgramList(companyQuery).then(res => {
      if(res.data){
        programs.value = res.data;
      }
    }),
    fetchCostCentreList(companyQuery).then(res => {
      if(res.data) {
        costCentres.value = res.data
      }
    }),
    fetchActivityList(companyQuery).then(res => {
      if(res.data){
        activities.value = res.data
      }
    }),
    fetchProjects(companyQuery).then(res => {
      if(res.data) {
        projects.value = res.data
      }
    }),
    fetchContactProfiles(query).then(res => {
      if(res.data) {
        contactProfiles.value = res.data;
      }
    }),
    fetchBusinessList(companyQuery).then(res => {
      if(res.data) {
        businesses.value = res.data;
      }
    }),
    fetchPrPoList(companyQuery).then(res => {
      if(res.data) {
        prPoLists.value = res.data;
      }
    }),
    fetchTagRegisterList(companyQuery).then(res => {
      if(res.data) {
        tagRegisters.value = res.data;
      }
    }),
    setVoucherNumberAndDate()
  ]).catch(err => {
    showError(err.message)
  }).finally(() => {
    isDataLoading.value = false;
  })
})
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }
    .label-min-width {
        min-width: 80px
    }
</style>
